import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { createClient } from '@supabase/supabase-js';
import { SupabaseContextProvider } from 'use-supabase';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from 'react-query/devtools';
import Honeybadger from '@honeybadger-io/js';
import ErrorBoundary from '@honeybadger-io/react';
import TagManager from 'react-gtm-module';
import GA4React from 'ga-4-react';
import 'index.css';
import App from 'App';

const revision = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || 'master';

// Configure honeybadger for error monitoring
const honeybadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.NODE_ENV,
  revision: revision
});

// Create supabase client
// Provides anonymous access to the supabase database
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

// Create query client
const queryClient = new QueryClient();

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM
});

const ga4react = new GA4React(process.env.REACT_APP_GA);

(async () => {
  try {
    await ga4react.initialize();
  } catch (e) {
    console.log('Adblock is activated, no biggie');
  }

  ReactDOM.render(
    <StrictMode>
      <ErrorBoundary honeybadger={honeybadger}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <SupabaseContextProvider client={supabase}>
              <App />
            </SupabaseContextProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
  );
})();
