import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageSpinner from 'components/PageSpinner';

const NotFound = lazy(() => import('pages/NotFound'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const AccountContainer = lazy(() => import('pages/account/AccountContainer'));
const Account = lazy(() => import('pages/account/Account'));
const Settings = lazy(() => import('pages/account/Settings'));
const Notifications = lazy(() => import('pages/account/Notifications'));
const BudgetsContainer = lazy(() => import('pages/budgets/BudgetsContainer'));
const Budgets = lazy(() => import('pages/budgets/Budgets'));
const AddBudget = lazy(() => import('pages/budgets/AddBudget'));
const InvestmentsContainer = lazy(() =>
  import('pages/investments/InvestmentsContainer')
);
const Investments = lazy(() => import('pages/investments/Investments'));
const AddInvestment = lazy(() => import('pages/investments/AddInvestment'));
const RecurringContainer = lazy(() =>
  import('pages/recurring/RecurringContainer')
);
const Recurring = lazy(() => import('pages/recurring/Recurring'));
const AddRecurring = lazy(() => import('pages/recurring/AddRecurring'));
const Support = lazy(() => import('pages/Support'));

function AuthenticatedApp() {
  return (
    <Suspense fallback={<PageSpinner />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="account" element={<AccountContainer />}>
            <Route path="/" element={<Account />} />
            <Route path="settings" element={<Settings />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
          <Route path="budgets" element={<BudgetsContainer />}>
            <Route path="/" element={<Budgets />} />
            <Route path="add" element={<AddBudget />} />
          </Route>
          <Route path="investments" element={<InvestmentsContainer />}>
            <Route path="/" element={<Investments />} />
            <Route path="add" element={<AddInvestment />} />
          </Route>
          <Route path="recurring" element={<RecurringContainer />}>
            <Route path="/" element={<Recurring />} />
            <Route path="add" element={<AddRecurring />} />
          </Route>
          <Route path="support" element={<Support />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default AuthenticatedApp;
