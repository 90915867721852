import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Spinner({ size }) {
  return (
    <div className="inline-flex items-center">
      <svg
        fill="none"
        viewBox="0 0 24 24"
        className={classNames(
          { 'h-5 w-5': size === 'sm', 'h-7 w-7': size === 'md' },
          'animate-spin text-blue-600'
        )}
      >
        <circle
          className="opacity-25"
          cx={12}
          cy={12}
          r={10}
          stroke="currentColor"
          strokeWidth={4}
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['sm', 'md'])
};

Spinner.defaultProps = {
  size: 'md'
};
