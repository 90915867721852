import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

function SEO({ title }) {
  const description = `Budgeting for everyone who wants to save, invest, and make their money work for them. It's simple with our tools to start taking charge of your money.`;

  const schemaOrg = {
    '@context': 'http://schema.org',
    '@type': 'WebApplication',
    url: 'https://slicesum.com',
    headline: 'Budgeting for everyone',
    inLanguage: 'en',
    description,
    name: title,
    genre: 'budgeting',
    browserRequirements: 'Requires JavaScript. Requires HTML5.',
    operatingSystem: 'All',
    applicationCategory: 'FinanceApplication',
    offers: {
      '@type': 'Offer',
      price: 0
    },
    softwareHelp: {
      '@type': 'CreativeWork',
      url: 'https://slicesum.com/help'
    },
    copyrightYear: '2020',
    maintainer: [
      {
        '@type': 'Person',
        name: '0xhjohnson',
        '@id': 'https://github.com/0xhjohnson'
      },
      {
        '@type': 'Person',
        name: 'Arthur Henry',
        '@id': 'https://github.com/ArthurWHenry'
      }
    ],
    author: [
      {
        '@type': 'Person',
        name: '0xhjohnson',
        '@id': 'https://github.com/0xhjohnson'
      },
      {
        '@type': 'Person',
        name: 'Arthur Henry',
        '@id': 'https://github.com/ArthurWHenry'
      }
    ],
    datePublished: '2020'
  };

  return (
    <Helmet
      title={title}
      defaultTitle="slicesum: budgeting for everyone"
      titleTemplate="%s · slicesum"
    >
      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string
};

SEO.defaultProps = {
  title: null
};

export default SEO;
