import SEO from 'components/SEO';

function WaitlistConfirm() {
  return (
    <>
      <SEO title="waitlist joined" />
      <div className="bg-gray-100 h-screen pt-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto">
            <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-gray-900 font-medium text-lg">
                  You are on the waitlist
                </h3>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <p className="text-gray-600">
                  Thanks for joining the waitlist, your spot is secured. We will
                  reach out via email once everything is polished and ready to
                  go.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WaitlistConfirm;
