import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Spinner from 'components/Spinner';

const NotFound = lazy(() => import('pages/NotFound'));
const Home = lazy(() => import('pages/Home'));
const Signup = lazy(() => import('pages/Signup'));
const EmailConfirm = lazy(() => import('pages/EmailConfirm'));

function UnauthenticatedApp() {
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="signup" element={<Signup />} />
          <Route path="confirm" element={<EmailConfirm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default UnauthenticatedApp;
