import { useUser, useSupabase } from 'use-supabase';
import { Toaster } from 'react-hot-toast';
import { useQuery } from 'react-query';
import * as R from 'remeda';
import Honeybadger from '@honeybadger-io/js';
import AuthenticatedApp from 'AuthenticatedApp';
import UnauthenticatedApp from 'UnauthenticatedApp';
import WaitlistConfirm from 'pages/WaitlistConfirm';
import PageSpinner from 'components/PageSpinner';

function App() {
  const user = useUser();
  const supabase = useSupabase();

  if (user) {
    Honeybadger.setContext({
      user_id: user?.id
    });
  }

  const { data: isActive, isLoading } = useQuery(
    ['is_active_user', user?.id],
    isActiveUser
  );

  async function isActiveUser() {
    if (!user) {
      return false;
    }

    const { data, error } = await supabase
      .from('profiles')
      .select('is_active')
      .eq('id', user.id);

    if (error) {
      Honeybadger.notify(error);
    }

    return R.first(data).is_active;
  }

  if (isLoading) return <PageSpinner />;

  let view;
  if (isActive) view = <AuthenticatedApp />;
  else view = <WaitlistConfirm />;

  return (
    <>
      {user ? view : <UnauthenticatedApp />}
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 4000,
          style: {
            padding: '1em'
          },
          className:
            'max-w-sm w-full bg-white shadow-lg border border-gray-200 rounded-lg font-medium text-gray-900 text-sm'
        }}
      />
    </>
  );
}

export default App;
